import { useEffect } from "react";
import MainFrame from "../../frames/MainFrame/MainFrame.frame";
import MyServices from "../../frames/MyServices/MyServices.frame";
import MySkills from "../../frames/MySkills/MySkills.frame";
import AppSpacer from "../../components/AppSpacer/AppSpacer.component";
import AppFooter from "../../components/AppFooter/AppFooter.component";

const Home = () => {
  useEffect(() => {
    document.title = "Welcome | Home";
  }, []);
  return (
    <div>
      <MainFrame />
      <MyServices />
      <MySkills />
      <AppSpacer vertical={"40px"} />
      <AppFooter />
    </div>
  );
};

export default Home;
