import { Button, Col, Container, Row } from "react-bootstrap";
import "./MainFrame.style.css";

import DeskInsan from "../../assets/img/mainFrame.svg";
import AppSpacer from "../../components/AppSpacer/AppSpacer.component";
import AppButton from "../../components/AppButton/AppButton.component";

const MainFrame = () => {
  return (
    <div className="main-frame-container">
      <Container fluid>
        <Row>
          <Col md={7}>
            <div className="mf-container-left">
              <div className="heading-container fontFamily-inter-bold">
                <span className="fontFamily-inter-bold display-5">
                  I'm Pratik Sonar
                  <br />
                  <span className="color-primary">Full-Stack</span> Developer
                </span>
                <br />
                <AppSpacer vertical={"20px"} />
                <span className="text-muted">
                  Innovative full stack developer Having An experience in
                  building and maintaining websites & web applications (PWA)
                </span>
              </div>
              <AppSpacer vertical={"20px"} />
              <div className="btn-container" style={{ width: 200 }}>
                <AppButton
                  StyleSx={{
                    backgroundColor: "#ff8000",
                    "&:hover": {
                      backgroundColor: "rgba(255, 128, 0, 0.9)",
                    },
                  }}
                >
                  Contact Me
                </AppButton>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="mf-container-right">
              <div className="img-container flip-horizontal">
                <img src={DeskInsan} alt="main-farame" className="img-fluid" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainFrame;
