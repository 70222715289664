import { Grid } from "@mui/material";
import "./App.css";
import Home from "./pages/Home/Home.page";

import web_dev from "./assets/img/services/web_dev.svg";
import { Card } from "react-bootstrap";

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
