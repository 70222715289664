import { Grid } from "@mui/material";
import { Card } from "react-bootstrap";

const ServiceCard = ({ CardImage, CardTitle, CardSubTitle }) => {
  return (
    <div>
      {/* <Grid container justifyContent={"center"} padding={2}>
        <Grid sm={4} item> */}
      <Card style={{borderRadius: "20px"}}>
        <div
          style={{
            margin: "auto",
            width: "70%",
          }}
        >
          <Card.Img
            variant="top"
            src={CardImage}
            style={{
              padding: "20px",
            }}
            height="220px"
          />
        </div>
        <Card.Body>
          <Card.Title style={{ textAlign: "center" }}>{CardTitle}</Card.Title>
          <Card.Text style={{ textAlign: "center" }}>{CardSubTitle}</Card.Text>
        </Card.Body>
      </Card>
      {/* </Grid>
      </Grid> */}
    </div>
  );
};

export default ServiceCard;
