const AppFooter = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "#fff",
        padding: "12px",
        textAlign: "center",
        fontSize: "12px"
      }}
    >
      <span className="text-muted fontFamily-inter-bold">
        {new Date().getFullYear()} All Rights Reserved. @pratik_sonar
      </span>
    </div>
  );
};

export default AppFooter;
