import { Grid } from "@mui/material";
import AppSpacer from "../../components/AppSpacer/AppSpacer.component";
import ServiceCard from "../../components/ServiceCard/ServiceCard.component";
import "./MyServices.style.css";
import web_dev from "../../assets/img/services/web_dev.svg";
import mobile_dev from "../../assets/img/services/mobile_dev.svg";
import ui_ux from "../../assets/img/services/ui_ux.svg";

const MyServices = () => {
  const servicesData = [
    {
      id: 1,
      title: "Web Development",
      subTitle: "Blog, E-Commerce",
      image: web_dev,
    },
    {
      id: 2,
      title: "Mobile App Development",
      subTitle: "Native, Android & IOS",
      image: mobile_dev,
    },
    {
      id: 3,
      title: "UI/UX Design",
      subTitle: "Mobile App, Website Design",
      image: ui_ux,
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid md={8} item>
          <div
            className="heading-container"
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "20px",
              padding: "12px",
            }}
          >
            <span className="display-5 fontFamily-inter-bold">My Services</span>
            <br />
            <AppSpacer vertical={"20px"} />
            <span className="text-muted fontFamily-inter-bold">
              {" "}
              As a full stack developer, I provide end-to-end web application
              solutions. I specialize in both front-end and back-end
              development, creating visually appealing user interfaces and
              robust server-side functionality.
            </span>
          </div>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>

      <Grid container spacing={2} padding={2}>
        {servicesData.map((service) => (
          <Grid item md={4} key={service.id} style={{ width: "100%" }}>
            <ServiceCard
              CardTitle={service.title}
              CardSubTitle={service.subTitle}
              CardImage={service.image}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MyServices;
