import { CardMedia, CardContent } from "@mui/material";
import "./SkillCard.style.css";
import { Card } from "react-bootstrap";

const SkillCard = ({ CardTitle, CardImage }) => {
  return (
    <div>
      <Card className="container">
        <div className="container-1">
          <Card.Img variant="left" src={CardImage} height={"100%"} />
          {/* <Card.Img
            component="img"
            image={
              CardImage
                ? CardImage
                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjxV1WKalnFaUJKxqxsgswTxfDMX069vkLGvsmcfGByQ&usqp=CAU&ec=48665701"
            }
            alt="default"
          ></Card.Img> */}
        </div>
        <div className="container-2">
          {/* <CardContent>
            <span className="container-text"> {CardTitle} </span>
          </CardContent> */}
          <Card.Body>
            <Card.Title>{CardTitle}</Card.Title>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

export default SkillCard;
