import { Grid } from "@mui/material";
import AppSpacer from "../../components/AppSpacer/AppSpacer.component";
import mongo from "../../assets/img/skills/mongo-icon.png";
import react from "../../assets/img/skills/react-icon.png";
import express from "../../assets/img/skills/express-icon.png";
import node from "../../assets/img/skills/node-icon.png";

import SkillCard from "../../components/SkillCard/SkillCard.component";
import "./MySkills.style.css";
import AppButton from "../../components/AppButton/AppButton.component";

const MySkills = () => {
  const servicesData = [
    {
      id: 1,
      title: "MongoDb",
      image: mongo,
    },
    {
      id: 2,
      title: "Express",
      image: express,
    },
    {
      id: 3,
      title: "React",
      image: react,
    },
    {
      id: 4,
      title: "Node",
      image: node,
    },
  ];

  const getLength = (text) => text.length;

  return (
    <div>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid md={8} item style={{ width: "100%" }}>
          <div
            className="heading-container"
            style={{
              textAlign: "center",
              marginTop: "40px",
              padding: "12px",
            }}
          >
            <span className="display-5 fontFamily-inter-bold">My Skills</span>
            <br />
            <AppSpacer vertical={"20px"} />
            <span className="text-muted fontFamily-inter-bold">
              The high-end software technologies that I use are following.
            </span>
          </div>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>

      <Grid container spacing={2} padding={2}>
        {servicesData.map((service, i) => (
          <Grid
            item
            xs={getLength(service.title) > 6 ? 12 : 6}
            md={3}
            key={i}
            style={{ width: "100%" }}
          >
            <SkillCard CardTitle={service.title} CardImage={service.image} />
          </Grid>
        ))}
      </Grid>
      <AppSpacer vertical={"20px"} />
      <div className="btn-container" style={{ width: 200, margin: "auto" }}>
        <AppButton
          StyleSx={{
            backgroundColor: "#ff8000",
            "&:hover": {
              backgroundColor: "rgba(255, 128, 0, 0.9)",
            },
          }}
        >
          View More
        </AppButton>
      </div>
    </div>
  );
};

export default MySkills;
